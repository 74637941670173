// src/UnityGame.js
import React, { useState, useEffect }  from 'react';
import { useLocation } from 'react-router-dom';
import './UnityGame.css';
import { Unity, useUnityContext  } from 'react-unity-webgl';
import lenskartLogo from './lenskartLogo.png';  // Import the image
import amazonFreshLogo from './amazonFreshLogo.png';
import myntraLogo from './myntraLogo.png';
import meeshoLogo from './meeshoLogo.png';
import swiggyLogo from './swiggyLogo.png';
import flipkartLogo from './flipkartLogo.png';
import zomatoLogo from './zomatoLogo.png';
import zeptoLogo from './zeptoLogo.png';
import amazonLogo from './amazonLogo.png';
import noiseLogo from './noiseLogo.png';
import boatLogo from './boatLogo.png';





const UnityGame = () => {

    // const location = useLocation();
    // const {
    //   unityProvider,
    //   isLoaded ,
    //   sendMessage,
    // } = useUnityContext({
    //   loaderUrl: "Build/WebGL-Dev.loader.js",
    //   dataUrl: "Build/WebGL-Dev.data",
    //   frameworkUrl: "Build/WebGL-Dev.framework.js",
    //   codeUrl: "Build/WebGL-Dev.wasm",
    //   streamingAssetsUrl:"StreamingAssets"
    // });

        const location = useLocation();
    const {
      unityProvider,
      isLoaded ,
      sendMessage,
      loadingProgression
    } = useUnityContext({
      loaderUrl: "Build/Final-Swiggy-Office-Demo.loader.js",
      dataUrl: "Build/webgl.data",
      frameworkUrl: "Build/build.framework.js",
      codeUrl: "Build/build.wasm",
      streamingAssetsUrl:"StreamingAssets"
    });

    
    // const location = useLocation();
    // const {
    //   unityProvider,
    //   isLoaded ,
    //   sendMessage,
    //   loadingProgression
    // } = useUnityContext({
    //   loaderUrl: "Build/Final-Build-1.loader.js",
    //   dataUrl: "Build/webgl.data",
    //   frameworkUrl: "Build/build.framework.js",
    //   codeUrl: "Build/build.wasm",
    //   streamingAssetsUrl:"StreamingAssets"
    // });

    //reachoutspaces@amazonfresh.com
    //reachoutspaces@lenskart.com
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email') || 'reachoutspaces@meesho.com';
    const name = searchParams.get('name') || 'reachoutspaces';
    const deviceId = searchParams.get('deviceId') || 'deviceIdmeesho4';
    const locationLat = parseFloat(searchParams.get('locationLat')) || 0.0;
    const locationLong = parseFloat(searchParams.get('locationLong')) || 0.0;
    const userData = searchParams.get('userData') || '';
  
    const gameArguments = {
      email,
      name,
      deviceId,
      locationLat,
      locationLong,
      userData
    };

    const sendArgumentsToUnity = (args) => {
        console.log("ARGUMENTS SENDING");
        console.log("ARGS: " + JSON.stringify(args));
        sendMessage("FirebaseManager", "ReceiveArguments", JSON.stringify(args));
    };


    useEffect(() => {
        if(isLoaded == true){
          sendArgumentsToUnity(gameArguments);
        }
    }, [isLoaded]);
  

  

    const [devicePixelRatio, setDevicePixelRatio] = useState(
        window.devicePixelRatio
    );
    
      useEffect(
        function () {
          // A function which will update the device pixel ratio of the Unity
          // Application to match the device pixel ratio of the browser.
          const updateDevicePixelRatio = function () {
            setDevicePixelRatio(window.devicePixelRatio);
          };
          // A media matcher which watches for changes in the device pixel ratio.
          const mediaMatcher = window.matchMedia(
            `screen and (resolution: ${devicePixelRatio}dppx)`
          );
          // Adding an event listener to the media matcher which will update the
          // device pixel ratio of the Unity Application when the device pixel
          // ratio changes.
          mediaMatcher.addEventListener("change", updateDevicePixelRatio);
          return function () {
            // Removing the event listener when the component unmounts.
            mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
          };
        },
        [devicePixelRatio]
    );

    

    const loadingPercentage = Math.round(loadingProgression * 100);

    return (
        <div style={{width: '100%', height: '100%', backgroundColor:"#0d0d0d", overflow: 'hidden', position: 'relative'}}>
        {/* {isLoaded === false && (
        // We'll conditionally render the loading overlay if the Unity
        // Application is not loaded.
        <div className="loading-overlay">
          <p>Loading... ({loadingPercentage}%)</p>
        </div>
        )} */}
          {isLoaded === false && (
            <div className="loading-overlay">
              <div className="loading-container">
              <div className="image-container">
                <img src={meeshoLogo} alt="Image 1" className="loading-image" />
              </div>
                <div className="loading-bar">
                  <div className="loading-fill" style={{ width: `${loadingPercentage}%` }}></div>
                </div>
                <div className="powered-by">
                  <span className="powered-by-text">powered by</span>
                  <span className="spaces-text">spaces</span>
                </div>
                {/* <p>Loading... ({loadingPercentage}%)</p> */}
              </div>
            </div>
          )}

        <Unity unityProvider={unityProvider} style={{ width: '100%', height: '100%' }} devicePixelRatio={devicePixelRatio}/>
        </div>
    );
};

export default UnityGame;